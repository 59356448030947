import VueRouter from 'vue-router';
import Dashboard from './views/pages/Dashboard.vue';
import UiKit from './views/pages/UiKit.vue';
import OrderOverview from './views/pages/OrderOverview.vue';

Vue.use(VueRouter);

let routes = [
    {
        path: '/',
        component: Dashboard,
        meta: {
            title: "dashboard",
        },
        alias: '/home'
    },
    {
        path: '/ui-kit',
        component: UiKit,
        meta: {
            title: "UI kit",
        },
    },
    {
        path: '/order-overview/:orderCode',
        name: 'order-overview',
        component: OrderOverview,
        meta: {
            title: "Order overview",
        },
        children: [
            {
                path: ':bundle/:definition/:objectId/:relationString?',
                name: 'order-overview-form',
                components:{
                    childview: {
                        template: '<cms-form v-on="$listeners"/>',
                    }
                },
            }
        ]
    },
];

routes = Vue.cmsRouter.setRoutes(routes);

const router = new VueRouter({
    routes
})

Vue.cmsRouter.setActiveRouter(router);

export default router;
